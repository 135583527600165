.zcvp-table-card {
    background: var(--colorBgHeader);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.zcvp-role-tag-primary {
    color: #9C3F8A;
    background: #DBCED6;
    border: 1px solid #C2ABBB;
}

.zcvp-role-tag-user {
    color: #0A62E2;
    background: #E6F4FF;
    border: 1px solid #85C2FF;
}

.ant-table-cell {
    border-inline-end: none !important;
}