.zcvp-address-field {
    .zcvp-select-field>div {
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        border-radius: 0;
        min-height: 32px;
        border: 1px solid #09214026;
        color: var(--colorTextBase);

        &:hover {
            border-color: var(--colorPrimaryBase);
        }

        [class*=indicatorSeparator] {
            display: none;
        }

        [class*=indicatorContainer] {
            padding: 4px;
        }
    }
}