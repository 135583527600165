.zcvp-card {
    margin-bottom: 1rem;
    background: var(--colorBgContainer);
    border: var(--colorBgContainer);
    color: var(--colorTextBase);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    &.ant-card {
        .ant-card-head {
            border-bottom: solid 1px var(--colorBorder);
        }
        .ant-card-actions{
            border-top: solid 1px var(--colorBorder);
            background: var(--colorBgContainer);
            li, li a {
                color: var(--colorTextAction);
            }
            &>li:not(:last-child) {
                border-inline-end: 1px solid var(--colorBorder);
            }
        }
    }
    .ant-card-head-title{
        font-size: 0.875rem;
        color: var(--colorTextBase);
        .anticon{
            color: var(--colorPrimaryBase);
        }
        .zcvp-card-subtitle {
            font-size: 0.8rem;
            font-weight: 400;
        }
    }
    .zcvp-card-footer{
        border-top: solid 1px grey;
    }
    &.zcvp-dash-card-parent {
        padding: 14px;
        &.ant-card .ant-card-head {
            padding: 1rem;
            border-bottom: none;
            .ant-card-head-title {
                font-family: Plus Jakarta Sans, sans-serif;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.75rem; /* 140% */

                .zcvp-card-title-notifications {
                    color: rgba(9, 33, 64, 0.45);

                    font-family: Plus Jakarta Sans, sans-serif;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem; /* 166.667% */
                }
                .zcvp-card-subtitle {
                    font-family: Plus Jakarta Sans, sans-serif;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.375rem; /* 157.143% */
                }
            }
        }
        .ant-card-body{
            padding: 0;
        }
        .zcvp-card.ant-card.zcvp-dash-card {
            width: 0;
            border-radius: 4px;
            border: 1px solid var(--colorPrimaryLight);
            color: var(--colorTextHeading, rgba(9, 33, 64, 0.88));
            display: flex;
            height:14rem;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            &.ant-card {
                .ant-card-body {
                    display: flex;
                    padding: 1rem 1.5rem 1.5rem 1.5rem;
                    align-items: center;
                    align-self: stretch;
                    color: var(--colorTextBase);
                }
                .ant-card-head {
                    display: flex;
                    padding: 1rem 1.5rem;
                    align-items: center;
                    gap: .5rem;
                    align-self: stretch;
                    border-bottom: 1px solid rgba(9, 33, 64, 0.06);
                    .ant-card-head-title {
                        color: var(--colorTextBase, rgba(9, 33, 64, 0.88));

                        /* LG/LG Strong */
                        font-family: Plus Jakarta Sans, sans-serif;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 1.5rem; /* 150% */
                        .anticon {
                            &.anticon-check{
                                border: 1px solid var(--colorPrimaryBorder, #FFBD7A);
                                color: var(--colorPrimaryBorder, #FFBD7A);
                            }
                            border-radius: 96px;
                            border: 1px solid var(--colorPrimaryBase, #FF6801);
                            background: var(--orange-1, #FFF4E6);
                            margin: .25rem .5rem .25rem 0;
                            height: 26px;
                            width: 26px;
                            display: flex;
                            padding: 5px;
                            align-items: flex-start;
                            gap: 8px;
                        }
                        svg {
                            height: 14px;
                            width: 14px;
                        }
                    }
                }
            }
        }
        .ant-table {
            border: 1px solid #F0F0F0;
            border-radius: 5px;
        }
        .ant-table-row:hover {
            cursor: pointer;
        }
        .ant-table-container {
            border-inline-start: none !important;
            border-top: none !important;
            border: none;
        }
        .ant-table-cell:not(:last-child) {
            border-inline-end: 1px solid #F0F0F0 !important;
        }
        .zcvp-table-card {
            box-shadow: none;
            margin: 0;
        }
        .zcvp-card-subtitle {
            margin-top: 11px;
        }
    }
}

.zcvp-card-title-text {
    font-size: 16px;
    color: var(--colorTextBase);
}
.zcvp-card-title-subtext {
    color: var(--colorTextSubtext) !important;
    font-weight: 400 !important;
    font-size: 14px !important;
}

.zcvp-dash-card-list {
    .zcvp-card-title-notifications {
        color: var(--colorTextBase) !important;
        font-size: 14px !important;
    }
}