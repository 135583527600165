:root.zcvp-theme-dark {
  --colorTextBase: #FFFFFF;
  --colorTextLabel: #c8d4e3; //rgba(255, 255, 255, .44);
  --colorTextSubtext: #c8d4e373;
  --colorTextAction: #FF6801;
  --colorTextLightSolid: #FFFFFF;
  --colorBorder: #3a2412;
  --colorBgContainer: #2D1603;
  --colorBgField: #1F0C00;
  --colorBgHeader: #2D1603;
  --colorPrimaryBase: #FF6801;
  --colorPrimaryBg: #1F0C00;
  --colorPrimaryMid: #371B05;
  --colorPrimaryDark: #1F0C00;
  --colorPrimaryMidAccent: rgba(255, 212, 163, .2);
  --font-family: Plus Jakarta Sans, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}