@import 'zcvp_common.scss';

.zcvp-notification {
    .rc-virtual-list-holder {
        max-height: calc(100vh - 200px);
    }
    .ant-popover-inner {
        padding: 24px 0 0 0;
    }
    .ant-popover-title {
        padding: 0 24px;
        color: var(--colorTextBase);
        font-weight: 400;
    }
    .ant-tabs-nav {
        padding: 0 24px;
        margin-bottom: 0;
    }
    .ant-tabs {
        width: 500px;
    }
    .ant-tabs-tab {
        padding: 8px 0;
        div[role="tab"] {
            color: var(--colorTextBase);
        }
    }
    .ant-tabs-content-holder {
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .ant-tabs-extra-content {
        display: flex;
        align-self: flex-start;
        gap: 8px;
        button {
            color: var(--colorTextBase);
            font-weight: 700;
        }
    }
    .ant-tabs-tab-btn {
        font-size: 14px;
    }
    .ant-tabs-tab-active {
        font-weight: 700;
    }
    .ant-list-item {
        padding: 12px 24px;
    }
    .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
        @include maxLines;
        color: var(--colorTextSubtext);
    }
    .ant-badge .ant-avatar {
        @include requestAvatar;
        border-color: var(--colorPrimaryBase);
        .anticon {
            color: var(--colorPrimaryBase);
        }
    }
    .ant-list-item:hover {
        cursor: pointer;
        background: #0921400A;
        .zcvp-notification-action {
            opacity: 1;
            .anticon {
                color: var(--colorTextBase);
            }
        }
    }
    .ant-empty-image {
        svg {
            @include emptyIcon;
        }
    }
    .zcvp-card-title {
        display: inline-block;
    }

    .zcvp-card-title-text {
        font-size: 14px;
        font-weight: 600;
        margin-right: 8px;
    }
    .zcvp-card-title-subtext {
        white-space: nowrap;
    }
}
.zcvp-notification-trigger.ant-badge {
    margin-left: auto;
    margin-right: 24px;
    margin-top: 8px;
    span[role="img"] {
        margin-right: 4px;
        cursor: pointer;
        svg {
            height: 18px !important;
            width: 18px !important;
            color: var(--colorPrimaryBg);
            margin-bottom: 8px;
        }
    }
    sup {
        margin-top: -5px;
        background-color: var(--colorPrimaryBase);
        box-shadow: none;
        border-radius: 2px !important;
        padding: 0 2px !important;
    }
}

.zcvp-empty-notif-list {
    width: 401px;
    margin: 0;
    padding: 24px 44px;
    .ant-empty-image {
        height: 125px !important;
        margin: 0;
    }
    svg {
        @include emptyIcon;
        width: 184px !important;
        height: 117 !important;
    }
}