.zcvp-phone-list {
    min-width: 80px;
}

.zcvp-phone-fld {
    .ant-form-item {
        margin-bottom: unset;
    }
    .ant-input-group-addon {
        padding: unset;
    }
    .ant-form-item-control-input {
        min-height: unset;
    }
    .ant-select-selection-item {
        overflow: unset;
        margin-inline-end: 18px;
    }
    .ant-select-selection-placeholder {
        min-width: 30px;
    }
}