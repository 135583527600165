:root {
    --colorTextBase: #092140E0;
    --colorTextLabel: var(--colorTextBase);
    --colorTextSubtext: #09214073;
    --colorTextAction: #092140E0;
    --colorTextLightSolid: #FFFFFF;
    --colorBorder: #09214026;
    --colorBgContainer: #FFFFFF;
    --colorBgField: #FFFFFF;
    --colorBgHeader: #FFFFFF;
    --colorPrimaryBase: #FF6801;
    --colorPrimaryBg: #FFF4E6;
    --colorPrimaryMid: #371B05;
    --colorPrimaryDark: #1F0C00;
    --colorPrimaryLight: #FFBD7A;
    --colorPrimaryLightAccent: #FFD4A3;
    --colorPrimaryMidAccent: rgba(255, 212, 163, .2);
    --colorPrimaryDarkAccent: #FFD4A322;
    --font-family: Plus Jakarta Sans, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}