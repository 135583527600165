.ant-modal-content {
    .zcvp-form-footer, .ant-modal-footer {
        @apply flex space-x-2 mt-4;
    
        .ant-btn-default {
            flex-grow: 0;
        }
    
        .ant-btn-primary {
            flex-grow: 1;
        }
    }

    .ant-modal-header {
        margin-bottom: 32px;

        .ant-modal-title {
            font-weight: bold;
        }
    }
}
