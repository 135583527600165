@import './zcvp_login.scss';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,600;0,700;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/** zcvp_app.css
 *
 *  Global styles for the Zone Capture Vendor Portal App
 *
 *  INDEX
 *    Global
 *    Text Styles
 *    Ant Design Overrides
 *    Media Queries
 */

/* -------------------------------------------------------------------------
 * Global
 * ------------------------------------------------------------------------- */
body {
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px
}

.zcvp-app {
    color: var(--colorTextBase);
}

.grecaptcha-badge {
    visibility: hidden !important;
}

/* -------------------------------------------------------------------------
 * Text styles
 * ------------------------------------------------------------------------- */

p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 157.143% */
}

a {
    font-weight: 600;
    color: inherit;
}

small {
    display: inline-block;
    line-height: 1.25rem;
    font-size: .75rem;
    color: var(--colorTextBase, rgba(9, 33, 64, 0.88));
    vertical-align: middle;
}

/* -------------------------------------------------------------------------
 * Form Overrides
 * ------------------------------------------------------------------------- */
.zcvp-hidden-label .ant-form-item-label {
    display: none;
}

/* -------------------------------------------------------------------------
 * Ant Design Overrides
 * ------------------------------------------------------------------------- */
button.ant-btn {
    font-weight: 600;
}

h2.ant-typography {
    font-family: var(--font-family);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.375rem; /* 126.667% */
    color: var(--colorTextBase);
}

.ant-form-item-label, .ant-form-item .ant-form-item-label > label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 157.143% */
    text-align: left;
    display: flex;
    padding-bottom: 0;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
}

.ant-btn-primary {
    box-shadow: none;
}