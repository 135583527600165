@mixin boxShadow {
    box-shadow: 0px 9px 28px 8px #0000000D, 0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014;
}

.zcvp-vertical-nav-is-open .zcvp-tabbed-page .ant-layout-header {
    padding-left: 0;
}

.zcvp-tabbed-page {
    .ant-breadcrumb-link:not(.zcvp-list-nav) {
        color: var(--colorTextSubtext);
    }

    .ant-breadcrumb-link .zcvp-list-nav {
        color: var(--colorTextBase);
    }

    .ant-layout-header {
        height: 100%;
        padding-left: 0;
        padding-right: 0;
        background: transparent;
    }

    .ant-tabs-nav {
        margin: 0;
        padding: 24px 42px 12px;
    }

    .ant-tabs-content-holder {
        margin-left: 0;
        margin-right: 0;
    }

    .ant-tabs-extra-content {
        @apply space-x-2;
    }

    .ant-layout-footer {
        padding: 20px;
    }

    .zcvp-sub-header {
        padding: 10px 28px;
        background: var(--colorBgHeader);
    }

    .zcvp-tab-content-pane {
        padding: 24px;
        background: var(--colorPrimaryBg);
    }

    .zcvp-tab-content-pane>*:first-child {
        margin-bottom: 1rem;
    }

    .zcvp-vertical-nav-toggle-button {
        margin-left: -0.75rem;
    }

    .ant-table-row:hover {
        cursor: pointer;
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-table-pagination {
        padding: 16px !important;
        margin: -1px 0 !important;
        border-top: 1px solid var(--colorBorder);
    }
}


/* -------------------------------------------------------------------------
 * Table Filter
 * ------------------------------------------------------------------------- */
.zcvp-filter-dropdown {
    @include boxShadow;
    padding: 0;
    .zcvp-search-filter-input {
        padding: 10px;
    }
    .zcvp-search-filter-checkbox-group {
        max-height: 212px;
        overflow: scroll;
        padding: 4px 4px;
    }
    .zcvp-filter-dropdown-button-panel {
        padding: 8px;
    }
    .ant-btn-text {
        margin: 0;
        padding-left: 10px;
    }
    .ant-btn-primary {
        float: right;
        margin: 0;
    }
    .anticon-search {
        color: #CECECE;
    }
    .ant-checkbox-group-item {
        padding: 4px 14px;
    }
    .ant-divider {
        margin: 0;
    }
    .ant-checkbox-group-item:hover {
        background-color: var(--colorPrimaryLightAccent);
    }
    label.ant-checkbox-wrapper-checked {
        background-color: var(--colorPrimaryBg);
    }
}

.zcvp-filter-panel {
    line-height: 32px;
    .ant-badge-count {
        margin-top: -20px;
        z-index: 1;
    }
    .ant-badge {
        opacity: 1;
    }
    .ant-badge sup {
        left: 8px;
    }
    .ant-badge sup[title="0"] {
        opacity: 0 !important;
    }
    .ant-dropdown-trigger:not(:last-of-type) .ant-tag {
        margin-inline-end: 0;
    }
    .flex.truncate {
        color: var(--colorTextBase);
    }
    .label-with-icon {
        font-weight: 700;
        font-size: 14px;
    }
    .ant-dropdown-trigger {
        cursor: pointer;
        line-height: 32px;
        color: var(--colorTextBase);
    }
    .zcvp-active-filter {
        color: #D94F00;
        border: 1px solid var(--colorPrimaryLight);
        background: var(--orange-1, #FFF4E6);
    }
    button {
        padding: 0;
        line-height: 20px;
        color: #092140A6;
        font-weight: 400;
        font-size: 12px;
        &:hover {
            background: unset !important;
            color: var(--colorTextBase);
        }
    }
}