@import 'zcvp_common.scss';

@mixin removeBottomBorder {
    border-bottom: unset;
    box-shadow: none;
}

@mixin baseTextStyle {
    background-color: unset;
    padding: unset;
    color: var(--colorTextBase);
}

@mixin descriptionLabel {
    font-size: 12px;
    width: 130px;
    color: var(--colorTextBase);
    font-weight: 700;
    border-inline-end: 0;
    padding: 2px 0;
    div {
        padding-top: 8px;
        padding-bottom: 8px;
        align-items: center;
    }

    .anticon {
        padding: 5px;
    }

    .label-with-icon {
        text-overflow: clip;
        overflow: unset;
    }
}

.zcvp-request-avatar {
    @include requestAvatar;
}

.zcvp-vendor-details {
    .ant-drawer-close {
        padding: 8px;
        margin-inline-end: 24px;
    }

    .ant-btn-primary {
        min-width: 180px;
    }

    .ant-drawer-title {
        color: var(--colorTextBase);
        font-size: 20px;
        font-weight: 700;
        padding-right: 24px;
        span.anticon-close {
            width: 14px;
            height: 14px;
        }
    }
    .ant-drawer-content-wrapper {
        min-width: 500px;
    }

    /** show bottom border on title when scrolling */
    .ant-drawer-header {
        @include removeBottomBorder;
        padding: 16px 24px;
        .ant-drawer-title {
            padding-top: 14px;
        }
    }
    .ant-drawer-header:not([data-scroll='0']) {
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        box-shadow: 0px 2px 4px 0px #00000005;
    }
    .ant-drawer-header:not([data-scroll]) {
        @include removeBottomBorder;
    }

    /** drawer content */
    .ant-drawer-body {
        padding: 0;
        color: var(--colorTextBase);

        .ant-skeleton-content {
            padding: 10px 24px !important;
        }

        .ant-descriptions {
            margin: 0 24px 0px 24px;
        }
        
        .ant-descriptions-view {
            border: 0;
            table {
                border-collapse: unset;
                table-layout: fixed;
            }
            table {
                th, td {
                    vertical-align: top;
                }
            }
            .ant-descriptions-row {
                .ant-descriptions-item-label,
                .ant-descriptions-item-content,
                .ant-descriptions-item-content .ant-typography,
                .ant-descriptions-item-content .ant-collapse-item .ant-collapse-header,
                .ant-descriptions-item-content .ant-collapse-item .ant-collapse-header-text,
                .ant-descriptions-item-content .ant-collapse-item .ant-collapse-content-box  {
                    @include baseTextStyle;
                }
                .ant-descriptions-item-label {
                    @include descriptionLabel;
                }

                /** collapsible field values */
                .ant-descriptions-item-content {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    vertical-align: middle;
                    padding-right: 46px;

                    .ant-typography, span:not(.ant-tag) {
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: unset;
                        color: var(--colorTextBase);
                    }
                    button {
                        padding: 0;
                    }
                    button > span {
                        padding: 0;
                        color: var(--colorPrimaryBase) !important;
                    }
                    .ant-typography div, a {
                        margin: 0 8px;
                    }
                    
                    .ant-collapse {
                        padding: 2px 0;
                    }

                    .ant-collapse-item .ant-collapse-header,
                    .ant-collapse-item .ant-collapse-content-box {
                        padding-bottom: unset;
                    }
                    .ant-collapse-item .ant-collapse-content-box div  {
                        margin-top: 12px;
                    }
                }
                .ant-collapse-expand-icon {
                    display: none;
                }

            }
        }
    }
    .ant-drawer-header-title > button {
        position: absolute;
        right: 0;
    }

    /** tab lists */
    .zcvp-request-list {
        .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
            margin: 2px 0;
            font-weight: 400 !important;
            color: var(--colorTextBase);
        }
        .ant-list-item {
            border-block-end: unset;
            color: var(--colorTextBase);
            .ant-avatar {
                @include requestAvatar;
            }
        }
    }
    .zcvp-show-inactive {
        font-weight: 400 !important;
        color: #092140A6;
        padding-left: 0;
    }
    .zcvp-show-inactive:hover {
        font-weight: 400 !important;
        color: var(--colorTextSubtext) !important;
    }

    /** all tabs */
    .ant-tabs-nav {
        margin-bottom: 16px;
    }
    .ant-tabs-nav-list {
        margin-left: 24px;
    }
    .ant-tabs-content-holder {
        padding: 0 24px
    }
    .ant-tabs-tab {
        color: var(--colorTextBase);
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        font-weight: 700;
    }

    /** vendor tabs */
    .zcvp-vendor-request {
        cursor: pointer;
    }
    .zcvp-venddetails-title-icon {
        border-right: 1px solid rgba(5, 5, 5, 0.06);
        padding-right: 16px;
    }
    .zcvp-venddetails-img-icon {
        @include requestAvatar;
        margin-top: 4px;
    }
    .zcvp-venddetails-title-icon + span.ant-avatar {
        @include requestAvatar;
        margin-left: 16px;
        margin-top: 4px;
    }
    .zcvp-venddetails-title-icon svg {
        width: 16px;
        height: 16px;
    }

    /** footer */
    .ant-drawer-footer {
        border-top: 1px solid var(--colorPrimaryLight);
        background-color: var(--colorPrimaryBg);
        padding: 16px 24px;
        .ant-btn {
            margin-left: auto;
        }
        text-align: right;
    }
    .ant-drawer-footer:empty {
        display: none;
    }
}

/** history **/
.zcvp-history-popover {
    .ant-popover-arrow, .ant-popover-title {
        display: none;
    }
}
.zcvp-history-item {
    padding: 3px;
    font-size: 12px;
    font-weight: 400 !important;
    color: #092140A6 !important;
    border-block-end: none !important;
    .ant-list-item-meta-content .ant-list-item-meta-title {
        font-size: 12px !important;
        font-weight: 400 !important;
        color: var(--colorTextBase) !important;
    }
    cursor: pointer;
}
.zcvp-history-details {
    width: 316px;
    .ant-descriptions-item-label {
        color: var(--colorTextBase);
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
    }
    .ant-descriptions-item-content {
        color: var(--colorTextBase);
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }
    .ant-descriptions-row:last-of-type .ant-descriptions-item {
        padding-bottom: 0;
    }
}


/** Comments */
.zcvp-comment-editor {
    color: var(--colorTextSubtext);
    gap: 10px;
    margin-top: 3px;
    textarea {
        padding-right: 30px;
    }
}
.zcvp-request-comment-list {
    .ant-list-item {
        padding-bottom: 0;
        border-block-end: none;
    }
    .ant-list-item-meta-title {
        font-size: 14px;
        font-weight: 700;
    }
    .ant-list-item-meta-description {
        color: var(--colorTextBase);
        font-size: 12px;
        font-weight: 400;
    }
    .ant-list-empty-text {
        display: none;
    }
}
.zcvp-send-btn {
    align-self: flex-end;
    margin-bottom: 8px;
    margin-left: -36px;
    z-index: 1;
    color: var(--colorTextBase);
}
.zcvp-send-btn.disabled {
    color: var(--colorTextSubtext);
}

/** Upload area */
.ant-upload > .flex.truncate {
    cursor: pointer;
}
.ant-upload.ant-upload-select {
    @include descriptionLabel;
    display: inline-block;
    margin: 0 26px;
}
.ant-upload-list {
    margin: 0 18px;
}
.ant-upload-list-item:hover {
    background-color: unset !important;
}
.ant-upload-list-item-name {
    color: var(--colorPrimaryBase);
    font-size: 14px;
    font-weight: 400;
}
.ant-upload-list-item-actions {
    margin-right: 4px;
}
.ant-upload-list .ant-upload-list-item-actions button,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
    color: var(--colorTextBase) !important;
    opacity: 1 !important;
    margin-left: 4px;
    margin-right: 4px;
}
.ant-upload-list-item-container {
    padding: 8px 16px;
}
.ant-upload-icon {
    display: none;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    margin: 0;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--colorTextSubtext);
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
    color: var(--colorTextSubtext);
    font-size: 14px;
    font-weight: 400;
}

/** Drawer */
.ant-drawer-extra {
    display: flex;
    gap: 16px;
}

.zcvp-hidden-file {
    button:last-of-type {
        display: none;
    }
}