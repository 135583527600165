.zcvp-role-popover {
    width: 232px;
    margin-top: -10px;

    .ant-popover-content {
        background: var(--colorPrimaryMid);
    }

    .ant-popover-inner {
        padding: 0;
        background: unset;
    }

    .ant-popover-inner-content {
        color: var(--colorTextLightSolid);

        div {
            padding-left: 8px;
            padding-right: 10px;
        }
    }

    .ant-popover-arrow,
    .ant-popover-content {
        margin-top: -10px;
    }

    .ant-popover-arrow:before {
        background: var(--colorPrimaryMid);
    }
}

.zcvp-role-popover-icon {
    margin-left: 4px;

    svg {
        fill: var(--colorTextSubtext);
    }
}

.ant-popover {
    max-width: 600px;
}