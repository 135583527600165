/** zcvp_no_auth.scss
 *
 *  Styles for layout used when user in unauthenticated the Zone Capture Vendor Portal App
 *
 *  INDEX
 *    Text
 *    Ant Design Overrides
 */

/* -------------------------------------------------------------------------
 * Text
 * ------------------------------------------------------------------------- */

.zcvp-unauthenticated-layout {

    .zcvp-no-auth-header-text {
        margin: 0 auto 3.375rem auto;
        text-align: center;
    }

    .zcvp-no-auth-logo {
        margin-top: 6.81rem;
        width: 9.9375rem;
        height: 9.9375rem;
        margin-bottom: 3.9375rem;
    }

    .zcvp-error-page {
        width: unset;
        height: unset;
    }
    .zcvp-no-auth-subtext {
        color: var(--colorTextDescription, rgba(9, 33, 64, 0.45));
    }

    .zcvp-error-page {
        p {
            font-size: 16px;
        }
        p, a:not(:hover) {
            color: var(--colorTextBase);
            font-weight: 700;
        }
    }

    /* -------------------------------------------------------------------------
     * AntD overrides
     * ------------------------------------------------------------------------- */

    .ant-form-item .ant-form-item-extra {
        min-height: initial;
    }

    .ant-layout {
        max-width: 42rem;
        min-width: 32.3rem;
    }
    
    .ant-form-item-label > label {
        color: var(--colorTextBase);
    }

}