@mixin maxLines {
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

@mixin requestAvatar {
    min-width: 24px;
    background: var(--colorPrimaryBg);
    border-color: var(--colorPrimaryLight);
    .anticon {
        color: var(--colorPrimaryLight);
        svg {
            padding: 1px;
        }
    }
}

@mixin emptyIcon {
    path {
        stroke: var(--colorPrimaryBorder, #FFBD7A);
        stroke-width: 0.4;
    }
    ellipse, path:last-child {
        fill: var(--colorPrimaryBg);
    }
}

.ant-empty-description {
    color: var(--colorTextSubtext) !important;
}