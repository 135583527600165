.zcvp-loader {
    line-height: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    .ant-progress-inner {
        background-color: transparent;
    }
    .ant-progress-bg, .ant-progress-inner {
        border-radius: 0;
        height: 4px;
    }
}