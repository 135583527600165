/** zcvp_auth.scss
 *
 *  Styles for the layout used for authenticated users
 *
 *  INDEX
 *    Variables
 *    Main Content
 *    Navigation
 */
@import 'zcvp_vendor_details.scss';
@import 'zcvp_tabbed_page.scss';

/* -------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------- */
$zcvp-nav-icon-size: 16px;
$stationaryNavWidth: 48px;
$toggleNavWidth: 240px; //this must match the toggleNavWidth const in ZVCPVerticalNavToggle.tsx

/* -------------------------------------------------------------------------
 * Main Content
 * ------------------------------------------------------------------------- */
.zcvp-main-content {
    transition: margin-left .4s ease;
    margin-left: 0;
    background: var(--colorPrimaryBg);

    .ant-tabs-nav {
        background: var(--colorBgHeader);
        padding-bottom: 1rem;
    }

    .ant-tabs-content-holder {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .ant-layout-header {
        padding-left: 0;
        padding-right: 0;
        height: 100%;
    }

    .zcvp-page-title {
        margin: 9px 0;
        padding-right: 24px;
        line-height: inherit;
    }

    .zcvp-basic-page {
        background: var(--colorPrimaryBg);

        .zcvp-vertical-nav-toggle-button {
            margin-left: -0.75rem;
        }

        //copying this css from tabbed page to keep a consistent look
        .ant-tabs {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.88);
            font-size: 14px;
            line-height: 1.5714285714285714;
            list-style: none;
            font-family: Plus Jakarta Sans, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            display: flex;
        }

        //copying this css from tabbed page to keep a consistent look
        .ant-layout-header {
            padding-left: 0;
            padding-right: 0;
            background: transparent;
        }

        //copying this css from tabbed page to keep a consistent look
        .ant-tabs-nav {
            margin: 0;
            padding: 24px 42px 12px;
            width: 100%;
        }
    }
}

.zcvp-vertical-nav-is-open {
    margin-left: $toggleNavWidth + $stationaryNavWidth;
}

.ant-btn:hover {
    cursor: pointer;
}

.ant-skeleton-content {
    padding: 24px;
}

.zcvp-main-skeleton {
    display: flex;
    flex-direction: column;

    .ant-skeleton-header {
        padding: 0;
        display: none;
    }

    .ant-skeleton-avatar {
        background: white !important;
        width: 100% !important;
        border-radius: 0 !important;
        height: 85px !important;
    }
}

/* -------------------------------------------------------------------------
 * Navigation
 * ------------------------------------------------------------------------- */
//toggles the drawer
.zcvp-vertical-nav-toggle-button:hover {
    color: var(--colorPrimaryBase);
}

.zcvp-role-menu-trigger {
    margin: 25px 0 25px 24px !important;
    .flex.truncate {
        max-width: 170px;
    }
}

.zcvp-role-menu {
    width: 279px;
    .bg-white > .ant-space > .ant-space-item {
        width: 100%;
    }
    .ant-dropdown-menu {
        box-shadow: none;
    }
    li.ant-dropdown-menu-item:not(.zcvp-signout) {
        padding: 8px 18px !important;
        .flex {
            color: var(--colorTextLabel);
        }
    }

    .ant-dropdown-menu-item.zcvp-signout {
        padding: 8px 24px;
        .flex {
            color: #D9312B;
        }
    }

    .ant-dropdown-menu-title-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ant-card-meta-detail {
        padding: 8px 28px;
        font-weight: 400;
        font-size: 14px;
        color: var(--colorTextBase);
        .ant-card-meta-title {
            color: #092140A6;
            margin-top: 6px;
        }
        .ant-card-meta-description {
            margin-top: 6px;
            .anticon-check {
                margin-left: auto;
            }
        }
    }
    .ant-dropdown-menu-item-group-title {
        color: var(--colorTextSubtext) !important;
    }
}

.zcvp-role-menu > div {
    @include boxShadow;
}

.zcvp-role-menu .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: var(--colorPrimaryBg) !important;
}

//zcvp-nav-vertical is the first (parent) level navigation menu
//zcvp-nav-vertical-toggle is the second (child) level navigation menu
.zcvp-vertical-nav-container {
    transition: ease-in-out .3s;
    white-space: nowrap;
    overflow-x: hidden;

    &.zcvp-nav-menu-mod-collapsed {
        transition: ease-in-out .3s;
        width: $stationaryNavWidth !important;
    }

    &.zcvp-nav-menu-mod-floating {
        overflow-x: visible;

        &.zcvp-nav-menu-mod-collapsed {
            .zcvp-nav-vertical-toggle {
                width: 0 !important;
            }
        }

        .zcvp-nav-vertical-toggle {
            z-index: 1001;
            transition: ease-in-out .3s;
        }

        .zcvp-nav-vertical-toggle-content {
            margin-top: 3.75rem;
            border-top-right-radius: .4rem;
        }
    }

    .ant-layout-sider-children {
        height: 100%;
    }

    .zcvp-nav-vertical {
        background: var(--colorPrimaryDark);
        left: 0;
        top: 0;
        z-index: 1002;
        position: fixed;
        min-height: 100vh;
        height: 100%;

        .zcvp-nav-item,
        .zcvp-collapse-caret {
            color: var(--colorPrimaryBase);
        }

        .zcvp-nav-item {
            margin: auto;
            margin-bottom: 16px;
        }

        .zcvp-collapse-caret {
            padding-bottom: 2.5rem;
            text-align: center;
            cursor: pointer;
        }
    }

    .zcvp-nav-vertical,
    .zcvp-nav-vertical-toggle {
        ul {
            padding: 0;
            list-style-type: none;

            li {
                .zcvp-nav-item {
                    text-decoration: none;
                    padding: .5rem .5rem;
                    cursor: pointer;
                    text-align: center;

                    &:hover {
                        color: var(--colorTextLightSolid)
                    }
                }

                img {
                    //currently the logos are the only img
                    display: block;
                    padding-bottom: .5rem;
                }

            }
        }

        svg {
            //currently all menu icons (and only menu icons) are svg
            height: $zcvp-nav-icon-size;
            width: $zcvp-nav-icon-size;
        }
    }

    .zcvp-nav-vertical-toggle {
        margin-left: $stationaryNavWidth;
        position: fixed;
        top: 0;
        bottom: 0;
        left: $stationaryNavWidth;
        inset: 0;
        width: $toggleNavWidth;
        min-height: 100vh;
        height: 100%;
        white-space: nowrap;
        overflow-x: hidden;
        display: flex;
        pointer-events: none;

        &.zcvp-nav-vertical-toggle-mod-collapsed {
            width: 0 !important;
        }

        .zcvp-nav-vertical-toggle-content {
            background: var(--colorPrimaryMid);
            display: flex;
            align-items: stretch;
            width: 100%;
            pointer-events: initial;

            ul {
                margin: 0;
                width: 100%;

                li:not(:last-child) {
                    margin-bottom: 8px;
                }

                .zcvp-nav-item {
                    margin: auto;
                }
            }

            .zcvp-nav-quick-search {
                display: flex;
                width: 214px;
                justify-content: center;
                align-items: center;
                gap: 16px;
                flex-shrink: 0;
                margin-bottom: 16px;

                &>div:first-child {
                    margin-left: 8px;
                }

                .ant-input-affix-wrapper {
                    padding-left: 12px;
                    padding-right: 12px;
                    height: 35px;

                    .ant-input-prefix {
                        margin-right: 10px;
                    }
                }

                .anticon-bell {
                    svg {
                        height: 22px;
                        width: 22px;
                    }

                    path {
                        transform: translateX(8rem);
                    }
                }
            }

            .zcvp-nav-notification {
                color: var(--colorPrimaryBg);
            }

            .zcvp-nav-item-large {
                color: var(--colorTextLightSolid);
                margin: 25px 24px;
                display: flex;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                align-items: center;

                .ant-dropdown-trigger {
                    .ant-space-item {
                        display: flex;
                    }
                }

                svg {
                    margin-right: 8px
                }

                &:hover {
                    color: var(--colorPrimaryLightAccent);
                    cursor: pointer;
                }
            }

            .zcvp-nav-item {
                color: var(--colorTextLightSolid);
                padding: 0 24px;
                font-weight: 700;
                line-height: 32px;
                height: 32px;
                transition: background .2s ease;

                &.zcvp-nav-item-mod-active {
                    background: var(--colorPrimaryMidAccent);
                }

                &:hover {
                    background: var(--colorPrimaryDarkAccent);
                }

                svg {
                    margin-right: 8px
                }

                .zcvp-nav-item-subtitle {
                    font-size: 12px;
                    font-weight: 400;
                    margin-left: 8px;
                }
            }

            .ant-input::placeholder,
            .ant-input,
            .ant-input-affix-wrapper {
                background: var(--colorPrimaryMid);
                color: var(--colorPrimaryLightAccent);
            }

            .ant-input-affix-wrapper {
                border-color: var(--colorPrimaryLightAccent);
            }
        }
    }
}

.zcvp-basic-content-centered {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
    gap: 10px !important;

}

.zcvp-auth-logo {
    width: 126px;
    height: 126px;
    margin: 36px 57px;
}


/* -------------------------------------------------------------------------
 * Request Modal
 * ------------------------------------------------------------------------- */
.zcvp-request-modal div.zcvp-form-footer {
    margin-top: 8px;
    // .ant-modal-content .zcvp-form-footer {
    // }
}

/* -------------------------------------------------------------------------
 * Confirmation Modal
 * ------------------------------------------------------------------------- */
.zcvp-vendor-save-confirmation.confirm-modal {
    width: 550px !important;
}
.confirm-modal .ant-modal-content {
    border-radius: 0;
    .ant-btn {
        border-radius: 0;
    }
    .ant-btn-primary, .ant-btn-primary:hover {
        background-color: var(--colorPrimaryBase);
    }
    .ant-btn-primary:hover {
        opacity: 0.8;
    }
    .ant-btn-default:hover {
        color: var(--colorPrimaryBase);
        border-color: var(--colorPrimaryBase);
    }
    .ant-modal-confirm-title {
        font-weight: 700;
    }
    .ant-modal-confirm-title, .ant-modal-confirm-content, .ant-btn-default {
        color: var(--colorTextBase);
    }
}

/* -------------------------------------------------------------------------
 * Form Field
 * ------------------------------------------------------------------------- */
.zcvp-form-field.zcvp-no-auth-subtext {
    color: var(--colorTextDescription, rgba(9, 33, 64, 0.45));
    margin: -12px 0 12px 0;
}
.ant-form-item-control-input textarea {
    resize: vertical !important;
}
.ant-form-item .ant-form-item-label > label {
    color: var(--colorTextBase);
    font-size: 14px;
    font-weight: 700;
}
.ant-modal-header .ant-modal-title {
    color: var(--colorTextBase);
    font-size: 16px;
    font-weight: 700;
}

/* -------------------------------------------------------------------------
 * Confirmation Modal
 * ------------------------------------------------------------------------- */
.zcvp-inline-field-label {
    color: var(--colorTextSubtext);
    margin: 8px 16px;
    font-weight: 400;
    font-size: 14px;
}

.zcvp-inline-field-value {
    margin: 8px 16px;
}

/* -------------------------------------------------------------------------
 * Tour
 * ------------------------------------------------------------------------- */
.ant-tour {
    z-index: 1003 !important;
    .ant-tour-inner {
        border-radius: 6px !important;
    }
    @at-root body {
        overflow-y: unset !important;
    }
}

.ant-tour-mask {
    z-index: 1002 !important;
}

.ant-tour.first-step {
    position: fixed;
    left: auto !important;
    top: auto !important;
    right: 24px !important;
    bottom: 16px !important;
}

.ant-tour-description a {
    color: var(--colorPrimaryBase);
}

.hide-next {
    display: none;
}

.first-step.ant-tour-mask {
    display: none;
}

.ant-alert-with-description {
    padding: 8px;
}


/* -------------------------------------------------------------------------
 * Dashboard
 * ------------------------------------------------------------------------- */
.zcvp-dashboard {
    .ant-alert {
        margin-bottom: 10px;
    }
}

.zcvp-form-footer {
    display: flex;
    gap: 8px;
}

/* -------------------------------------------------------------------------
 * Notifications
 * ------------------------------------------------------------------------- */
