.zcvp-select-field {
    min-width: 200px;
    max-width: 100%;
    .ant-select-selection-item-content {
        color: var(--colorTextMain);
        margin: 0;
    }
    .ant-select-selection-item {
        color: var(--colorTextMain);
        background-color: transparent;
        padding: 0;
        height: unset;
    }
    .ant-tag {
        margin: 0 2px;
    }
}